import {createRouter, createWebHistory} from 'vue-router';

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('../views/HomeView.vue'),
		},
		{
			path: '/checkout',
			name: 'checkout',
			component: () => import('../views/Checkout.vue'),
		},
		{
			path: '/purchase-complete',
			name: 'thank-you',
			component: () => import('../views/ThankYou.vue'),
		},
		{
			path: '/:catchAll(.*)',
			redirect: '/',
		},
	],
});

export default router;
