import toast, {POSITION} from 'vue-toastification';
import './assets/main.css';
// Import the CSS or use your own!
import VueCountdown from '@chenfengyuan/vue-countdown';
import * as Sentry from '@sentry/vue';
import {createApp} from 'vue';
import VueConfetti from 'vue-confetti';
import 'vue-toastification/dist/index.css';
import App from './App.vue';
import router from './router';

const app = createApp(App);
app.use(VueConfetti);
app.use(toast, {
	// Setting the global default position
	position: POSITION.BOTTOM_RIGHT,
});

app.component(VueCountdown.name, VueCountdown);

Sentry.init({
	app,
	dsn: 'https://0ac2c9b1c3ba171154c1f197846e8135@o4506161330257920.ingest.sentry.io/4506161332224000',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [
				'localhost',
				/^https:\/\/chatgptpromptspro\.com/,
			],
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);

app.mount('#app');
